import React from "react"
import LazyLoad from "react-lazyload"
import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
  Icon,
} from "@lesmills/gatsby-theme-common"

import type { ImageType } from "@lesmills/gatsby-theme-common"

type DetailProps = {|
  intensity: Array<Field>,
  intensity_icon?: ImageType,
  length_icon?: ImageType,
  length?: Array<Field>,
  plan?: Array<Field>,
  equipment_required?: Array<Field>,
  equipment_list?: ImageType,
|}

const DetailInfo = ({
  intensity,
  intensity_icon = {
    url: "",
    alt: "",
  },
  length_icon = {
    url: "",
    alt: "",
  },
  length = {
    raw: [],
  },
  plan = {},
  equipment_required = {},
  equipment_list = {},
}: DetailProps) => (
  <>
    {/* Intensity */}
    <div className="challenge-intensity flex flex-row justify-center items-center px-15 py-12 border-t border-b border-gray-550">
      <LazyLoad height="h-challenge-intensity-icon">
        <img
          className="object-cover h-challenge-intensity-icon challenge-intensity-icon"
          src={intensity_icon.url}
          alt={intensity_icon.alt}
        />
      </LazyLoad>
      <RichText
        render={intensity.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "font-primary lg:text-lg text-gray-500 text-base leading-20 my-0 ml-12 challenge-desc-text"
        )}
      />
    </div>

    {/* Length & Plan */}
    <div className="py-15 px-15 border-b border-gray-550">
      <div className="flex flex-row justify-center items-center mb-20">
        <LazyLoad height="h-challenge-length-icon">
          <img
            className="object-cover h-challenge-length-icon challenge-length-icon"
            src={length_icon.url}
            alt={length_icon.alt}
          />
        </LazyLoad>
        <RichText
          render={length.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-primary lg:text-lg text-gray-500 text-base leading-20 ml-12 challenge-desc-text"
          )}
        />
      </div>

      {/* Plan */}
      <div className="flex flex-row justify-center items-center">
        <Icon
          icon="plan"
          type="base"
          classNames="opacity-50 bg-cover plan-icon-base"
        />
        <RichText
          render={plan.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-primary lg:text-lg text-gray-500 text-base leading-20 ml-12 challenge-desc-text"
          )}
        />
      </div>
    </div>
    {/* Equipment */}
    <div className="flex flex-col text-center mb-25 justify-center py-15 px-15 border-b border-gray-550">
      <RichText
        render={equipment_required.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "font-primary lg:text-lg text-gray-500 text-base leading-20 challenge-desc-text mb-5"
        )}
      />
      {equipment_list.url && (
        <LazyLoad height="h-plan-icon-base">
          <img
            className="object-cover h-plan-icon-base w-challenge-equipment-list mx-auto"
            src={equipment_list.url}
            alt={equipment_list.alt}
          />
        </LazyLoad>
      )}
    </div>
  </>
)

export default DetailInfo
