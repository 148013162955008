import React from "react"
import type {
  ChallengeType,
  ChallengesPageType,
} from "../../../../types/ChallengesPageType"
import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
  Button,
  Icon,
  LoadingIndicator,
  isBrowser,
  USER_INFO_KEY,
  getLocalStorage,
  pushTracking,
  PARAMS,
  EVENT,
} from "@lesmills/gatsby-theme-common"

// Partially Components
import BasicInfo from "./BasicInfo"
import Thumbnail from "./Thumbnail"
import DetailInfo from "./DetailInfo"

type Props = {|
  challenge: ChallengeType,
  activeChallenge: ChallengeType,
  data: ChallengesPageType,
  setAcceptingChallenge: () => void,
  setPlayingVideo: () => void,
  setStoppingChallenge: () => void,
  setSwitchingChallenge: () => void,
  active: boolean,
  isActiveSubscription: boolean,
  loading: boolean,
|}

const ChallengeItem = ({
  challenge,
  data,
  activeChallenge,
  setAcceptingChallenge = () => {},
  setPlayingVideo = () => {},
  setStoppingChallenge = () => {},
  setSwitchingChallenge = () => {},
  active = false,
  isActiveSubscription = true,
  loading = true,
}: Props) => {
  const {
    challenge_thumbnail,
    challenge_name,
    challenge_author,
    challenge_duration,
    challenge_video_url,
    challenge_vimeo_url = {
      url: "",
    },
    challenge_description = {},
    challenge_intensity_icon = {},
    challenge_intensity = {},
    challenge_length_icon = {},
    challenge_length = {},
    challenge_plan = {},
    challenge_equipment_required = {},
    challenge_equipment_list = {},
    download_pack_button_display,
    download_pack_link,
    view_video_button_disable,
  } = challenge || {}

  const {
    get_challenge_button_label = {},
    view_workouts_button_label = {},
    challenge_word = {},
    stop_challenge_label = {},
    download_pack_button_label,
  } = data || {}

  const user = getLocalStorage(USER_INFO_KEY)
  const { vimeoUserTicket } = user?.userEntitlements?.LMOD || {}
  return (
    <section className="h-full flex flex-col px-12 lg:px-4">
      <div className="relative h-450">
        {active && (
          <Icon
            icon="mask"
            type="base"
            classNames="mask-icon-large absolute right-0 top-0 z-10 h-74 w-74 bg-cover"
          />
        )}
        <BasicInfo
          duration={challenge_duration}
          name={challenge_name}
          author={challenge_author}
          challengeWord={challenge_word}
          video={challenge_video_url}
          setPlayingVideo={setPlayingVideo}
          active={active}
        />

        {/* Challenge thumbnail */}
        <Thumbnail source={challenge_thumbnail} />
      </div>

      <div className="flex-grow border-r border-l border-gray-550">
        <div className="pt-20 pb-20 px-10">
          <RichText
            render={challenge_description.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "challenge-description font-base-light text-gray-800 text-base mb-20"
            )}
          />
        </div>
      </div>
      <div>
        <div className="challenge-detail-info border-gray-550 border-r border-l border-b">
          <DetailInfo
            intensity={challenge_intensity}
            intensity_icon={challenge_intensity_icon}
            length_icon={challenge_length_icon}
            length={challenge_length}
            plan={challenge_plan}
            equipment_required={challenge_equipment_required}
            equipment_list={challenge_equipment_list}
            isActiveSubscription={isActiveSubscription}
          />
          {/**
           * JIRA ticket: https://lesmillsinternational.atlassian.net/browse/ENG-630
           * Only show these buttons for active subscription
           */}

          {loading ? (
            <LoadingIndicator className="relative challenge-indicator" />
          ) : (
            <div className="px-15">
              <Button
                handleOnClick={() => {
                  active
                    ? setStoppingChallenge(challenge)
                    : activeChallenge
                    ? setSwitchingChallenge(challenge)
                    : setAcceptingChallenge(challenge)
                }}
                disabled={!isActiveSubscription}
                submitting={false}
                className={`btn btn-primary-gray pt-3 pb-3 mb-15 w-full ${
                  active ? "btn-primary-red" : ""
                } ${isActiveSubscription ? "" : "btn-disabled"}`}
              >
                {active
                  ? stop_challenge_label.text
                  : get_challenge_button_label.text}
              </Button>
              {challenge_vimeo_url?.url && (
                <Button
                  handleOnClick={() =>
                    isBrowser &&
                    (window.location.href = `${challenge_vimeo_url.url}?ticket=${vimeoUserTicket}`)
                  }
                  disabled={!isActiveSubscription || view_video_button_disable}
                  submitting={false}
                  className={`btn btn-layout pt-3 pb-3 mb-15 w-full ${
                    isActiveSubscription ? "" : "btn-disabled"
                  }`}
                >
                  {view_workouts_button_label.text}
                </Button>
              )}
              {download_pack_button_display && download_pack_button_label && (
                <Button
                  handleOnClick={() => {
                    pushTracking({
                      event: EVENT.CONTENT_DOWNLOAD,
                      [PARAMS.CONTENT_TITLE]: challenge_name?.text,
                      [PARAMS.CONTENT_TYPE]: "other",
                      [PARAMS.CONTENT_TOPIC]: "challenge",
                      [PARAMS.CONTENT_LOCATION]: `Challenges - ${challenge_name?.text}`,
                    })
                    isBrowser &&
                      (window.location.href = `${download_pack_link}`)
                  }}
                  disabled={!isActiveSubscription}
                  submitting={false}
                  className={`btn btn-layout pt-3 pb-3 mb-15 w-full ${
                    isActiveSubscription ? "" : "btn-disabled"
                  }`}
                >
                  {download_pack_button_label}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ChallengeItem
