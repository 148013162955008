import React from "react"
import LazyLoad from "react-lazyload"

import type { ImageType } from "@lesmills/gatsby-theme-common"

type ThumbnailProps = {|
  source: {
    url: string,
    alt: string,
    Mobile?: ImageType,
    Tablet?: ImageType,
  },
|}

const Thumbnail = ({
  source = {
    url: "",
    alt: "",
    thumbnails: {
      Mobile: {},
      Tablet: {},
    },
  },
}: ThumbnailProps) => {
  const thumbnails = source.thumbnails

  return (
    <LazyLoad height="h-450">
      <picture>
        <source srcSet={source.url} media="(min-width: 1598px)" />
        <source srcSet={thumbnails.Tablet.url} media="(min-width: 769px)" />
        <img
          alt={thumbnails.Mobile.alt}
          src={thumbnails.Mobile.url}
          className="object-cover h-450 absolute top-0 left-0 w-full"
          data-cy="challenge-thumbnail"
        />
      </picture>
    </LazyLoad>
  )
}

export default Thumbnail
